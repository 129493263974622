<template>
    <div>
      <v-card>
        <v-card-title>
          Survey Parameters
          <v-spacer />
        </v-card-title>

        <v-card-text>
          <ul>
            <li>The Excel file must be in .csv</li>
            <li>The mandatory columns are <code>bpid</code>, <code>bcid</code>, <code>brid</code>. Any other columns in the Excel file will be treaten as parameters to save</li>
            <li>Any existing data will be overwritten if the same combination of <code>bpid</code>, <code>bcid</code>, <code>brid</code> exists</li>
            <li><code>bpid</code>, <code>bcid</code> can be hard coded if you know what you are doing!</li>
          </ul>
          
          <v-file-input
            label="Upload File"
            persistent-hint
            hint="The format of the file should be csv."
            accept=".csv"
            @change="selectFile"
            :rules="[v => !!v || 'File is required']"
          />

          <v-btn class="primary mt-3" @click="assignProject">Upload Parameters</v-btn>
        </v-card-text>

      </v-card>
    </div>
</template>

<script>
/* eslint-disable */

import RestResource from "../../services/dataServiceBuyer";
const service = new RestResource();
var csvjson = require("csvjson");

export default {
  data() {
    return {
      parameters: []
    };
  },

  mounted() {

  },

  methods: {
    selectFile(e) {
      if (e) {
        const self = this;
        const file = e;
        this.buttonText = file.name;

        let reader = new FileReader();
        reader.onload = () => {
          self.importedData = reader.result;

          let csvData = csvjson.toObject(reader.result, {});
          this.parameters = this.processsParameters(csvData);
        };

        reader.readAsText(file);
      }
    },

    processsParameters(csvData) {
      let parameters = [];

      for (let row of csvData) {
        if (row.brid && row.bcid && row.bpid && row.brid != "" && row.bpid != "" && row.bcid != "") {
          parameters.push(row)
        } else {
          this.$store.state.showDialogMessage = true
          this.$store.state.dialogMessageTitle = "Error"
          this.$store.state.dialogMessageText = `Missing required parameters!`
        }
      }

      return Array.from(new Set(parameters));
    },
    async assignProject() {
      if (this.parameters.length > 0) {
        this.$setLoader()

        for (let row of this.parameters) {
          await service.uploadSurveyParameters(row)
        }

        this.$showSuccessMessage({ message: `Succesfully uploaded ${this.parameters.length} survey parameters` })
      }
    }
  }
};
</script>